import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "locations", "themes", "portrait"];

  initialize() {
    this.handleChange = this.handleChange.bind(this);
  }

  connect() {
    this.searchTarget.addEventListener("keyup", this.handleChange);
    this.searchTarget.addEventListener("search", this.handleChange);
    this.locationsTarget.addEventListener("change", this.handleChange);
    this.themesTarget.addEventListener("change", this.handleChange);

    this.updateForm();
    this.filter();
  }

  handleChange() {
    this.updateParams();
    this.filter();
  }

  updateForm() {
    const params = new URLSearchParams(window.location.search);

    this.searchTarget.value = params.get("q") || "";
    this.locationsTarget.value = params.get("l") || "";
    this.themesTarget.value = params.get("t") || "";
  }

  updateParams() {
    const query = new URLSearchParams({
      q: this.searchTarget.value,
      l: this.locationsTarget.value,
      t: this.themesTarget.value
    }).toString()

    const path = window.location.origin + window.location.pathname + "?" + query;
    window.history.pushState({ path }, "", path);
  }

  filter() {
    const query = [
      this.searchTarget.value,
      this.locationsTarget.value,
      this.themesTarget.value
    ].filter((v) => v).join(" ").toLowerCase().split(/\s+/);

    const isMatch = (query, value) => query.every((q) => value.find((v) => v.includes(q)))

    this.portraitTargets.forEach((portrait) => {
      const value = portrait.dataset.searchValues.split(" ");

      if (isMatch(query, value)) {
        portrait.classList.remove("hidden");
      } else {
        portrait.classList.add("hidden");
      }
    })
  }
}

