import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "target", "frame"];

  initialize() {
    this.play = this.play.bind(this);
  }

  connect() {
    this.triggerTarget.addEventListener("click", this.play);
  }

  play() {
    this.triggerTarget.classList.add("hidden");
    this.targetTarget.classList.remove("hidden");
    this.frameTarget.src = this.frameTarget.dataset.src;
  }
}
