import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['image', 'caption', 'viewer', 'viewerImage']

  initialize () {
    this.currentImage = 0
  }
  connect(){
    this.viewerTarget.style["transition-duration"]=`${this.viewerTarget.dataset.lightboxTransitionDuration}ms`
  }

  show (event) {
    event.preventDefault()
    const item = event.params.item
    this.showImage(item)
    document.body.classList.add("overflow-hidden")
  }

  close (event) {
    event.preventDefault()
    if (this.viewerTarget.dataset.lightboxTransitionHide) {
      this.viewerTarget.classList.add(this.viewerTarget.dataset.lightboxTransitionHide)
      this.viewerTarget.classList.remove(this.viewerTarget.dataset.lightboxTransitionShow)
      setTimeout(()=>this.viewerTarget.classList.add("hidden"),Number(this.viewerTarget.dataset.lightboxTransitionDuration))
    } else {
      this.viewerTarget.classList.add("hidden")
    }
    document.body.classList.remove("overflow-hidden")
  }

  next (event) {
    event.preventDefault()

    if (this.currentImage === this.imageTargets.length - 1) {
      this.currentImage = 0
      this.showImage(this.currentImage)
    } else {
      this.currentImage += 1
      this.showImage(this.currentImage)
    }
  }

  previous (event) {
    event.preventDefault()

    if (this.currentImage === 0 ) {
      this.currentImage = this.imageTargets.length - 1
      this.showImage(this.currentImage)
    } else {
      this.currentImage -= 1
      this.showImage(this.currentImage)
    }
  }

  // Internal
  showImage (item) {
    this.currentImage = item;
    this.viewerTarget.classList.remove('hidden')
    setTimeout(()=>{
      if (this.viewerTarget.dataset.lightboxTransitionShow) {
        this.viewerTarget.classList.remove(this.viewerTarget.dataset.lightboxTransitionHide)
        this.viewerTarget.classList.add(this.viewerTarget.dataset.lightboxTransitionShow)
      }
    },100)

    this.viewerImageTarget.src = this.imageTargets[item].href

    const children = Array.from(this.imageTargets[item].children[0].children)
    const caption = children.find((x) => x.dataset.lightbox === "caption")

    if (caption) {
      this.captionTarget.innerHTML = caption.innerHTML
    } else {
      this.captionTarget.innerHTML = ""
    }
  }
}
